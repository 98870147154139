module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"d6e144c5-e0cc-508f-ba6b-c406b470d695","name":"gatsby-remark-images","version":"3.2.2","pluginOptions":{"plugins":[],"backgroundColor":"none"},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{"default":"/opt/build/repo/src/components/Layout.js"},"gatsbyRemarkPlugins":["gatsby-remark-unwrap-images",{"resolve":"gatsby-remark-images","options":{"disableBgImageOnAlpha":true,"withWebp":true,"maxWidth":550,"linkImagesToOriginal":false,"quality":85,"wrapperStyle":"\n                width: 100%;\n              "}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"none"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Juan Luna Ramirez","short_name":"juanlunaramirez","description":"Juan is a JavaScript developer based in Los Angeles, California. Come check out what he's been working on lately.","start_url":"/","lang":"en","background_color":"#014807","theme_color":"#18981D","display":"standalone","icon":"src/images/icon.png"},
    }]
